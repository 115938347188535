import AdminHeader from '../../../../Layout/Admin/Header/AdminHeader'
import AdminMobileHeader from '../../../../Layout/Admin/MobileHeader/AdminMobileHeader'
import AdminSidebar from '../../../../Layout/Admin/Sidebar/AdminSidebar'
import React, { FC, ReactNode, useEffect } from 'react'
import './AdminWrapper.scss'
import { TGModalAlert } from '../../../../Components'
import { useDispatch, useSelector } from 'react-redux'
import { SHOW_ALERT } from '../../../../Constants/AppAlertConstants'
import { appAlertType } from 'Reducers/AppAlerts/AppAlertsReducer'
import { useNavigate } from 'react-router-dom'

interface propTypes {
  children: ReactNode
}

const AdminWrapper: FC<propTypes> = ({ children }) => {
  // MODAL ALERT
  const dispatcher = useDispatch()

  const handleCloseAlertModal = () => {
    dispatcher({ type: SHOW_ALERT, payload: { message: '', title: '', show: false } })
  }

  const appAlert = useSelector((state: { appAlert: appAlertType }) => state?.appAlert)

  useEffect(() => {
    const handleUnload = () => {
      // Clear data from local storage
      // localStorage.removeItem('__TG');
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // User is inactive, clear data from local storage
        // localStorage.removeItem('__TG');
      }
    }

    window.addEventListener('beforeunload', handleUnload)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('beforeunload', handleUnload)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    const checkSession = () => {
      const loginTime = localStorage.getItem('loginTime')
      if (loginTime) {
        const currentTime = Date.now()
        const timeElapsed = currentTime - parseInt(loginTime, 10)
        // const twelveHours = 12 * 60 * 60 * 1000 // 12 hours in milliseconds
        const twelveHours = 10 * 60 * 1000

        if (timeElapsed > twelveHours) {
          logoutUser()
        } else {
          // Set a timer to log out the user exactly after remaining time
          setTimeout(() => {
            logoutUser()
          }, twelveHours - timeElapsed)
        }
      }
    }

    checkSession()
  }, [])

  const navigate = useNavigate()

  const logoutUser = async () => {
    await localStorage.removeItem('__TG')
    await localStorage.removeItem('loginTime')

    setTimeout(() => {
      alert('Session Expired')
      navigate('/login')
    }, 2000)
  }

  return (
    <React.Fragment>
      <div className='adminWraper p-0 m-0 w-100'>
        <div className='row'>
          <div className='col-25 pe-0 '>
            <AdminSidebar></AdminSidebar>
          </div>
          <div className='col-85 p-0'>
            <AdminMobileHeader />
            <AdminHeader></AdminHeader>
            {children}
          </div>
        </div>
      </div>

      <TGModalAlert
        show={appAlert?.show}
        title={appAlert?.title}
        message={appAlert?.message}
        onClose={handleCloseAlertModal}
        type={appAlert?.type}
      />
    </React.Fragment>
  )
}

export default AdminWrapper
